<template>
  <div class="pt-8">
    <CyEventsTimeline v-bind="{ events: _.map(notifications, 'event') }"/>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CyEventsTimeline from '@/components/events/timeline.vue'
import { constructBreadcrumb } from '@/utils/helpers'

export default {
  name: 'CyPageNotifications',
  components: {
    CyEventsTimeline,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.notifications'), [])
  },
  header () {
    return {
      title: this.$t('routes.notifications'),
    }
  },
  computed: {
    ...mapState('notifications', [
      'notifications',
    ]),
  },
  mounted () {
    this.GET_NOTIFICATIONS()
  },
  methods: {
    ...mapActions('notifications', [
      'GET_NOTIFICATIONS',
    ]),
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.notifications',
      },
      es: {
        title: '@:routes.notifications',
      },
      fr: {
        title: '@:routes.notifications',
      },
    },
  },
}
</script>
